<template>
	<v-card :loading="isSaving">
		<v-card-text class="px-0 pb-0">
			<v-form ref="localizationSettingsForm" lazy-validation v-model="formValid">
				<v-row no-gutters>
					<v-col class="item-form-column white pa-4">
						<v-card class="pa-2" flat>
							<fieldset class="item-legend-rounded-wrapper px-5 rounded pb-5">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'Financial Year' }}
								</legend>								
								<v-row>
									<v-col md="12">
										<p class="mb-0">Set the financial year period that your company uses for taxing and reporting.</p>
									</v-col>
								</v-row>
								<v-row class="mt-0">
									<v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-financial-start"
												>{{ 'Financial Year Start' }}</label
											>
										</div>
                                        <v-menu
											ref="financialYearStartMenu"
											v-model="financialYearStartMenu"
											close-on-content-click
											transition="scale-transition"
											max-width="290px"
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													:value="financialStartDateFormatted"
													label="Financial Start"
													persistent-hint
													append-icon="mdi-calendar"
													v-bind="attrs"
													v-on="on"
													id="formdata-financial-start"
													solo
													dense
													flat
													outlined
													:rules="[(v) => !!v || 'Field Required']"
												></v-text-field>
											</template>
											<v-date-picker
												v-model="financialPeriodStart"
												no-title
												@input="financialYearStartMenu = false"
											></v-date-picker>
										</v-menu>
									</v-col>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-financial-denote"
												>{{ 'Date Denote' }}</label
											>
										</div>
										<v-select
											v-model="formData.financial_denote"
											:items="financialDenoteList"
											:rules="
												!formData.financial_denote
													? [(v) => !!v || 'Field Required']
													: []
											"
											id="formdata-financial-denote"
											:label="'Select Financial Denote'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
								</v-row>
							</fieldset>
							<fieldset class="item-legend-rounded-wrapper px-5 rounded mt-4 pb-4">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'Prefered Date' }}
								</legend>
								<v-row>
									<v-col md="12">
										<p class="mb-0">Select the date format that you want to see everywhere in the interface.</p>
									</v-col>
								</v-row>
								<v-row class="mt-0">
									<v-col md="6" cols="12">
										<label class="form-label text-uppercase" for="formdata-date-format">{{
											'Date Format'
										}}</label>
										<v-select
											v-model="formData.date_format"
											:items="dateFormatList"
											id="formdata-date-format"
											:label="'Select Date Format'"
											solo
											flat
											outlined
											dense
											hide-details
											clearable
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.date_format
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-select>
									</v-col>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-date-separator"
												>{{ 'Date Separator' }}</label
											>
										</div>
										<v-select
											v-model="formData.date_separator"
											:items="dateSeparatorList"
											:rules="
												!formData.date_separator
													? [(v) => !!v || 'Field Required']
													: []
											"
											id="formdata-date-separator"
											:label="'Select Date Separator'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
								</v-row>
							</fieldset>
							<fieldset class="item-legend-rounded-wrapper px-5 rounded mt-4 pb-4">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'Other' }}
								</legend>
								<v-row>
									<v-col md="12">
										<p class="mb-0">Select where the percentage sign is displayed for taxes. You can enable discounts on line items and at the total for invoices and bills.</p>
									</v-col>
								</v-row>
								<v-row class="mt-0">
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-timezone"
												>{{ 'Timezone' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.timezone"
											:items="timezoneList"
											id="formdata-timezone"
											:label="'Select Timezone'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.timezone
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-percent-position"
												>{{ 'Percent (%) Position' }}</label
											>
										</div>
										<v-select
											v-model="formData.percent_position"
											:items="percentPositionList"
											id="formdata-percent-position"
											:label="'Select Percent Position'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
                                    <v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-discount-location"
												>{{ 'Discount Location' }}</label
											>
										</div>
										<v-select
											v-model="formData.discount_location"
											:items="discountLocationList"
											id="formdata-discount-location"
											:label="'Select Discount Location'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
								</v-row>
							</fieldset>
						</v-card>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>

		<!-- <v-divider></v-divider> -->

		<v-card-actions class="d-none d-sm-flex">
			<v-btn
				color="primary"
				@click="onSaveForm()"
				class="text-capitalize btn-primary btn-blue"
				:disabled="!formValid || isSaving"
				:loading="isSaving"
				>{{ 'Save' }}</v-btn
			>
			<v-btn outlined text class="text-capitalize primary--text btn-white" @click="onClose" :disabled="isSaving">{{
				'Cancel'
			}}</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
		<v-card-actions class="d-flex d-sm-none">
			<v-menu offset-x>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="btn-blue"
						dark
						v-bind="attrs"
						v-on="on"
						:loading="isSaving"
						class="d-flex align-center"
					>
						{{ 'Actions' }}
						<v-divider vertical class="mx-2" />
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</template>
				<v-list dense class="text-uppercase">
					<v-list-item @click="onSaveForm()">
						<v-list-item-content>
							<v-list-item-title>{{ 'save' }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-spacer></v-spacer>
			<v-btn outlined text class="text-capitalize primary--text btn-white" @click="onClose" :disabled="isSaving">{{
				'Cancel'
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import AccountingService from '../services/accounting.service';
import accountingGlobalMethods, { apiErrorMessage } from '../utils/accountingGlobalMethods';
import moment from 'moment';

export default {
    name: "LocalizationSettings",
    components: {
		// 
    },
	props: ['open'],
	data() {
		return {
			formData: {
                financial_start: '',
                financial_denote: '',
                timezone: '',
                date_format: '',
                date_separator: '',
                percent_position: '',
                discount_location: '',
			},
			formValid: true,
			isSaving: false,
			financialYearStartMenu: null,
			financialPeriodStart: '',
			dateSeparatorList: [],
            dateFormatList: [],
            financialDenoteList: [],
            percentPositionList: [],
            discountLocationList: [],
            timezoneList: [],
		};
	},

	created() {
        this.getLocalizationSettings();
	},

	computed: {
		financialStartDateFormatted() {
			return this.financialPeriodStart ? moment(this.financialPeriodStart).format("MMM DD") : "";
		},
		isMobile() {
			return this.$vuetify.breakpoint.mobile;
		}
	},

	watch: {
		financialPeriodStart(value){
			this.formData.financial_start = moment(value).format("DD-MM")
		}
	},

	methods: {
		...accountingGlobalMethods,
		async getLocalizationSettings(){
			try {
				const { data } = await AccountingService.getlocalizationSettings();
				if(data){
                    console.log(data);
                    const current_values = data.data.defaults;
                    const options = data.data.options;

                    this.dateSeparatorList = Object.keys(options.date_separators).map((c) => { return { value:c, text:options.date_separators[c] } });
                    this.dateFormatList = Object.keys(options.date_formats).map((c) => { return { value:c, text:options.date_formats[c] } });
                    this.financialDenoteList = Object.keys(options.financial_denote_options).map((c) => { return { value:c, text:options.financial_denote_options[c] } });
                    this.percentPositionList = Object.keys(options.percent_positions).map((c) => { return { value:c, text:options.percent_positions[c] } });
                    this.discountLocationList = Object.keys(options.discount_locations).map((c) => { return { value:c, text:options.discount_locations[c] } });
                    
					let timezones = [];
					Object.keys(options.timezones).forEach((key) => {
						timezones.push({ header: key });
						Object.keys(options.timezones[key]).forEach((k) => {
							timezones.push({
								text: options.timezones[key][k],
								value: k
							});
						});
					});
					this.timezoneList = timezones;

                    this.formData = {
                        ...this.formData,
                        financial_denote: current_values.financial_denote,
                        timezone: current_values.timezone,
                        date_format: current_values.date_format,
                        date_separator: current_values.date_separator,
                        percent_position: current_values.percent_position,
                        discount_location: current_values.discount_location,
                    }
					this.financialPeriodStart = moment(current_values.financial_start,"DD-MM").add(0,"Y").format("YYYY-MM-DD");
				}
			
			} catch (error) {
                console.log(error);
				apiErrorMessage(error);
			}
		},

		onClose() {
			this.$refs.localizationSettingsForm.resetValidation();
			this.$emit('toggle');
		},

		async onSaveForm() {
			if (this.isSaving) {
				return;
			}
			const validated = this.$refs.localizationSettingsForm.validate();
			if (validated) {
				this.isSaving = true;
				try {
					const { data } = await AccountingService.saveLocalizationSettings({
						...this.formData,
					});
					const message = data.message || 'Data was successfully saved.';
                    this.$emit('toggle', { created: true, message });
				} catch (error) {
					const { data } = error.response || { data: {} };
					const message = data.message || 'Could not save the data.';
					this.$emit('toggle', { created: false, message });
				} finally {
					this.showSnackbar = true;
					this.isSaving = false;
				}
			}
		},
	}
};
</script>

<style lang="scss">
@import '../../../src/assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../src/assets/scss/buttons.scss';
@import '../views/scss/globalAccounting.scss';
</style>

<style lang="scss" scoped>
	$form-label: #819fb2;
	$border-color: #21606bab;
	$btn-active-color: #0171a1;

	.form-label,
	.v-label {
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	fieldset {
		border: 1px solid $border-color;
		border-radius: 5px 5px 5px 5px;
	}

	fieldset legend {
		border: 1px solid;
		border-color: $border-color;
		background-color: #f5f5f5;
	}
	.btn-primary {
		background-color: $btn-active-color !important;
		color: #ffffff !important;
	}
	::v-deep {
		.v-dialog {
			.v-input__control {
				background: transparent !important;
				margin-bottom: 12px !important;

				.v-input__slot {
					min-height: 45px !important;

					label {
						line-height: 18px;
						font-size: 14px;
						color: #B4CFE0;
						font-family: 'Inter-Regular', sans-serif;
					}

					input,
					textarea {
						font-size: 14px;
						font-family: 'Inter-Regular', sans-serif;
					}

					.v-select__slot {
						.v-select__selections {
							.v-select__selection {
								font-size: 14px;
							}
						}
					}

					.v-input__append-inner {
						button {
							font-size: 18px;
							color: #ff5252!important;
						}
					}
				}

				.v-text-field__details {
					margin-bottom: 0;
					padding-top: 5px !important;
				}
			}

			.v-input.v-input--is-focused {
				fieldset {
					border: 1px solid #0171A1 !important;
				}
			}

			.v-text-field--enclosed .v-input__prepend-inner {
				margin-top: 0 !important;
			}

			.item-form-column {
				padding: 20px 24px !important;

				.form-label {
					font-size: 10px;
					color: #819fb2;
					font-family: "Inter-SemiBold",sans-serif;
					text-transform: uppercase;
					margin-bottom: 0;
				}
			}

			.item-legend-rounded-wrapper {
				border: 1px solid #D8E7F0;

				.item-legend-rounded {
					border: 1px solid #D8E7F0;
					padding: 6px 16px !important;
					text-transform: uppercase;
					color: #6D858F;
					font-size: 12px;
					font-family: 'Inter-Medium', sans-serif;
				}
			}			
		}

		.v-select--is-menu-active {
			background: transparent !important;
		}

		fieldset {
			border: 1px solid #b3cfe0;
			font-size: 14px;
		}

		fieldset:focus {
			border: 1px solid #b3cfe0 !important;
			outline: 0;
		}

		.v-text-field__slot input::placeholder {
			color: #b4cfe0 !important;
		}
	}
</style>
