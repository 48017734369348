<template>
	<v-card :loading="isSaving">
		<v-card-text class="px-0 pb-0">
			<v-form ref="defaultForm" lazy-validation v-model="formValid">
				<v-row no-gutters>
					<v-col class="item-form-column white pa-4">
						<v-card class="pa-2" flat>
							<fieldset class="item-legend-rounded-wrapper px-5 rounded pb-5">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'General' }}
								</legend>
								<v-row>
									<v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-account"
												>{{ 'Account' }}</label
											>
										</div>
										<v-select
											v-model="formData.account"
											:items="accountsList"
											:rules="
												!formData.account
													? [(v) => !!v || 'Field Required']
													: []
											"
											id="formdata-account"
											:label="'Select Account'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-currency"
												>{{ 'Currency' }}</label
											>
										</div>
										<v-select
											v-model="formData.currency"
											:items="currenciesList"
											:rules="
												!formData.currency
													? [(v) => !!v || 'Field Required']
													: []
											"
											id="formdata-curreny"
											:label="'Select Currency'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
								</v-row>
                                    <v-row>
									<v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-tax"
												>{{ 'Tax' }}</label
											>
										</div>
										<v-select
											v-model="formData.tax"
											:items="taxesList"
											id="formdata-tax"
											:label="'Select Tax'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-payment-method"
												>{{ 'Payment Method' }}</label
											>
										</div>
										<v-select
											v-model="formData.payment_method"
											:items="paymentMethodsList"
											id="formdata-payment-method"
											:label="'Select Payment Method'"
											solo
											flat
											outlined
											dense
											hide-details
											clearable
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
								</v-row>
							</fieldset>
							<fieldset class="item-legend-rounded-wrapper px-5 rounded mt-4 pb-4">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'Category' }}
								</legend>
								<v-row>
									<v-col md="6" cols="12">
										<label class="form-label text-uppercase" for="formdata-income-category">{{
											'Income Category'
										}}</label>
										<v-select
											v-model="formData.income_category"
											:items="incomeCategoriesList"
											id="formdata-income-category"
											:label="'Select Income Category'"
											solo
											flat
											outlined
											dense
											hide-details
											clearable
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.income_category
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-select>
									</v-col>
									<v-col md="6" cols="12">
										<label class="form-label text-uppercase" for="formdata-expense-category">{{
											'Expense Category'
										}}</label>
										<v-select
											v-model="formData.expense_category"
											:items="expenseCategoriesList"
											id="formdata-expense-category"
											:label="'Select Expense Category'"
											solo
											flat
											outlined
											dense
											hide-details
											clearable
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.expense_category
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-select>
									</v-col>
								</v-row>
							</fieldset>
							<fieldset class="item-legend-rounded-wrapper px-5 rounded mt-4 pb-4">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'Other' }}
								</legend>
								<v-row>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-locale"
												>{{ 'Locale' }}</label
											>
										</div>
										<v-select
											v-model="formData.locale"
											:items="localesList"
											id="formdata-locale"
											:label="'Select Locale'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.locale
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-select>
									</v-col>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-list-limit"
												>{{ 'Records Per Page' }}</label
											>
										</div>
										<v-select
											v-model="formData.list_limit"
											:items="listLimitList"
											id="formdata-list-limit"
											:label="'Select List Limit'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-select>
									</v-col>
								</v-row>
							</fieldset>
						</v-card>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>

		<!-- <v-divider></v-divider> -->

		<v-card-actions class="d-none d-sm-flex">
			<v-btn
				color="primary"
				@click="onSaveForm()"
				class="text-capitalize btn-primary btn-blue"
				:disabled="!formValid || isSaving"
				:loading="isSaving"
				>{{ 'Save' }}</v-btn
			>
			<v-btn outlined text class="text-capitalize primary--text btn-white" @click="onClose" :disabled="isSaving">{{
				'Cancel'
			}}</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
		<v-card-actions class="d-flex d-sm-none">
			<v-menu offset-x>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="btn-blue"
						dark
						v-bind="attrs"
						v-on="on"
						:loading="isSaving"
						class="d-flex align-center"
					>
						{{ 'Actions' }}
						<v-divider vertical class="mx-2" />
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</template>
				<v-list dense class="text-uppercase">
					<v-list-item @click="onSaveForm()">
						<v-list-item-content>
							<v-list-item-title>{{ 'save' }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-spacer></v-spacer>
			<v-btn outlined text class="text-capitalize primary--text btn-white" @click="onClose" :disabled="isSaving">{{
				'Cancel'
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import AccountingService from '../services/accounting.service';
import accountingGlobalMethods, { apiErrorMessage } from '../utils/accountingGlobalMethods';

export default {
	props: ['open'],
	data() {
		return {
			formData: {
				account: '',
				currency: '',
				tax: '',
				income_category: '',
				expense_category: '',
				list_limit: '',
				locale: '',
				payment_method: '',
			},
			formValid: true,
			isSaving: false,
			accountsList: [],
            currenciesList: [],
            expenseCategoriesList: [],
            incomeCategoriesList: [],
            localesList: [],
            paymentMethodsList: [],
            taxesList: [],
            listLimitList: [
                { value: 10, text: 10 },
                { value: 15, text: 15 },
                { value: 25, text: 25 },
                { value: 35, text: 35 },
                { value: 50, text: 50 },
                { value: 100, text: 100 },
            ]
		};
	},

	created() {
        this.getDefaults();
	},

	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.mobile;
		}
	},

	watch: {
		//
	},

	methods: {
		...accountingGlobalMethods,

		async getDefaults(){
			try {
				const { data } = await AccountingService.getAccountingDefaults();
				if(data){
                    const current_values = data.data.defaults;
                    const options = data.data.options;

                    this.accountsList = Object.keys(options.accounts).map((c) => { return { value:c, text:options.accounts[c] } });
                    this.currenciesList = Object.keys(options.currencies).map((c) => { return { value:c, text:options.currencies[c] } });
                    this.expenseCategoriesList = Object.keys(options.expense_categories).map((c) => { return { value:c, text:options.expense_categories[c] } });
                    this.incomeCategoriesList = Object.keys(options.income_categories).map((c) => { return { value:c, text:options.income_categories[c] } });
                    this.localesList = Object.keys(options.locales).map((c) => { return { value:c, text:options.locales[c] } });
                    this.paymentMethodsList = Object.keys(options.payment_methods).map((c) => { return { value:c, text:options.payment_methods[c] } });
                    this.taxesList = Object.keys(options.taxes).map((c) => { return { value:c, text:options.taxes[c] } });

                    this.formData = {
                        ...this.formData,
                        account: current_values.account,
                        currency: current_values.currency,
                        tax: current_values.tax,
                        income_category: current_values.income_category,
                        expense_category: current_values.expense_category,
                        list_limit: current_values.list_limit ? parseInt(current_values.list_limit) : 25,
                        locale: current_values.locale,
                        payment_method: current_values.payment_method,
                    }
				}
			
			} catch (error) {
				apiErrorMessage(error);
			}
		},

		onClose() {
			this.$refs.defaultForm.resetValidation();
			this.$emit('toggle');
		},

		async onSaveForm() {
			if (this.isSaving) {
				return;
			}

			const validated = this.$refs.defaultForm.validate();
			if (validated) {
				this.isSaving = true;
				try {
					const { data } = await AccountingService.saveDefaultSettings({
						...this.formData,
					});
					const message = data.message || 'Data was successfully saved.';
                    this.$emit('toggle', { created: true, message });
				} catch (error) {
					const { data } = error.response || { data: {} };
					const message = data.message || 'Could not save the data.';
					this.$emit('toggle', { created: false, message });
				} finally {
					this.showSnackbar = true;
					this.isSaving = false;
				}
			}
		},
	}
};
</script>

<style lang="scss">
@import '../../../src/assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../src/assets/scss/buttons.scss';
@import '../views/scss/globalAccounting.scss';
</style>

<style lang="scss" scoped>
	$form-label: #819fb2;
	$border-color: #21606bab;
	$btn-active-color: #0171a1;

	.form-label,
	.v-label {
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	fieldset {
		border: 1px solid $border-color;
		border-radius: 5px 5px 5px 5px;
	}

	fieldset legend {
		border: 1px solid;
		border-color: $border-color;
		background-color: #f5f5f5;
	}
	.btn-primary {
		background-color: $btn-active-color !important;
		color: #ffffff !important;
	}
	::v-deep {
		.v-dialog {
			.v-input__control {
				background: transparent !important;
				margin-bottom: 12px !important;

				.v-input__slot {
					min-height: 45px !important;

					label {
						line-height: 18px;
						font-size: 14px;
						color: #B4CFE0;
						font-family: 'Inter-Regular', sans-serif;
					}

					input,
					textarea {
						font-size: 14px;
						font-family: 'Inter-Regular', sans-serif;
					}

					.v-select__slot {
						.v-select__selections {
							.v-select__selection {
								font-size: 14px;
							}
						}
					}

					.v-input__append-inner {
						button {
							font-size: 18px;
							color: #ff5252!important;
						}
					}
				}

				.v-text-field__details {
					margin-bottom: 0;
					padding-top: 5px !important;
				}
			}

			.v-input.v-input--is-focused {
				fieldset {
					border: 1px solid #0171A1 !important;
				}
			}

			.v-text-field--enclosed .v-input__prepend-inner {
				margin-top: 0 !important;
			}

			.item-form-column {
				padding: 20px 24px !important;

				.form-label {
					font-size: 10px;
					color: #819fb2;
					font-family: "Inter-SemiBold",sans-serif;
					text-transform: uppercase;
					margin-bottom: 0;
				}
			}

			.item-legend-rounded-wrapper {
				border: 1px solid #D8E7F0;

				.item-legend-rounded {
					border: 1px solid #D8E7F0;
					padding: 6px 16px !important;
					text-transform: uppercase;
					color: #6D858F;
					font-size: 12px;
					font-family: 'Inter-Medium', sans-serif;
				}
			}			
		}

		.v-select--is-menu-active {
			background: transparent !important;
		}

		fieldset {
			border: 1px solid #b3cfe0;
			font-size: 14px;
		}

		fieldset:focus {
			border: 1px solid #b3cfe0 !important;
			outline: 0;
		}

		.v-text-field__slot input::placeholder {
			color: #b4cfe0 !important;
		}
	}
</style>
