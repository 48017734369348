<template>
	<v-card :loading="isSaving">
		<v-card-text class="px-0 pb-0">
			<v-form ref="localizationSettingsForm" lazy-validation v-model="formValid">
				<v-row no-gutters>
					<v-col class="item-form-column white pa-4">
						<v-card class="pa-2" flat>
							<fieldset class="item-legend-rounded-wrapper px-5 rounded pb-5">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'Default Chart of Accounts' }}
								</legend>
								<v-row class="mt-0">
									<v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-accounts-recievable"
												>{{ 'Accounts Receivable' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.accounts_receivable"
											:items="accountsOptions"
											id="formdata-accounts-recievable"
											:label="'Select Accounts Receivable'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.accounts_receivable
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
									<v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-accounts-payable"
												>{{ 'Accounts Payable' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.accounts_payable"
											:items="accountsOptions"
											id="formdata-accounts-payable"
											:label="'Select Accounts Payable'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.accounts_payable
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
                                    <v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-sales"
												>{{ 'Sales' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.accounts_sales"
											:items="accountsOptions"
											id="formdata-sales"
											:label="'Select Accounts Sales'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.accounts_sales
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
									<v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-general-expenses"
												>{{ 'General Expenses' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.accounts_expenses"
											:items="accountsOptions"
											id="formdata-general-expenses"
											:label="'Select General Expenses'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.accounts_expenses
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
                                    <v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-sales-discount"
												>{{ 'Sales Discount' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.accounts_sales_discount"
											:items="accountsOptions"
											id="formdata-sales-discount"
											:label="'Select Sales Discount'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.accounts_sales_discount
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
									<v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-purchase-discount"
												>{{ 'Purchase Discount' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.accounts_purchase_discount"
											:items="accountsOptions"
											id="formdata-purchase-discount"
											:label="'Select Purchase Discount'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.accounts_purchase_discount
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
                                    <v-col md="6" cols="12">
                                        <div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-owners-contribution"
												>{{ 'Owners Contribution' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.accounts_owners_contribution"
											:items="accountsOptions"
											id="formdata-owners-contribution"
											:label="'Select Owners Contribution'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.accounts_owners_contribution
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
								</v-row>
							</fieldset>
                            <fieldset class="item-legend-rounded-wrapper px-5 rounded mt-4 pb-4">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'Default Types' }}
								</legend>
								<v-row class="mt-0">
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-bank-and-cash"
												>{{ 'Bank and Cash' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.types_bank"
											:items="typesOptions"
											id="formdata-bank-and-cash"
											:label="'Select Bank And Cash Type'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
                                            :rules="
												!formData.types_bank
													? [(v) => !!v || 'Field Required']
													: []
											"
										>
										</v-autocomplete>
									</v-col>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-tax"
												>{{ 'Tax' }}</label
											>
										</div>
										<v-autocomplete
											v-model="formData.types_tax"
											:items="typesOptions"
											id="formdata-tax"
											:label="'Select Tax Type'"
											solo
											flat
											outlined
											dense
											hide-details
											:menu-props="{ bottom: true, offsetY: true, contentClass: 'accounting-lists-items' }"
										>
										</v-autocomplete>
									</v-col>
								</v-row>
							</fieldset>
                            <fieldset class="item-legend-rounded-wrapper px-5 rounded mt-4 pb-4">
								<legend class="item-legend-rounded rounded pa-2 mb-4">
									{{ 'Manual Journal Entry' }}
								</legend>
								<v-row class="mt-0">
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-number-prefix"
												>{{ 'Number Prefix' }}</label
											>
										</div>
										<v-text-field
											v-model.number="formData.journal_number_prefix"
											solo
											outlined
											flat
											dense
											:label="'Enter Prefix'"
											id="formdata-number-prefix"
											type="text"
											:rules="[(v) => !!v || 'Field Required']"
										></v-text-field>
									</v-col>
									<v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-number-digit"
												>{{ 'Number Digit' }}</label
											>
										</div>
										<v-text-field
											v-model.number="formData.journal_number_digit"
											solo
											outlined
											flat
											dense
											:label="'Enter Digit'"
											id="formdata-number-digit"
											type="number"
											:rules="[(v) => !!v || 'Field Required']"
										></v-text-field>
									</v-col>
                                    <v-col md="6" cols="12">
										<div class="d-flex align-center justify-space-between">
											<label
												class="form-label text-uppercase"
												for="formdata-number-next"
												>{{ 'Next Number' }}</label
											>
										</div>
										<v-text-field
											v-model.number="formData.journal_number_next"
											solo
											outlined
											flat
											dense
											:label="'Enter Next Number'"
											id="formdata-number-next"
											type="number"
											:rules="[(v) => !!v || 'Field Required']"
										></v-text-field>
									</v-col>
								</v-row>
							</fieldset>
						</v-card>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>

		<!-- <v-divider></v-divider> -->

		<v-card-actions class="d-none d-sm-flex">
			<v-btn
				color="primary"
				@click="onSaveForm()"
				class="text-capitalize btn-primary btn-blue"
				:disabled="!formValid || isSaving"
				:loading="isSaving"
				>{{ 'Save' }}</v-btn
			>
			<v-btn outlined text class="text-capitalize primary--text btn-white" @click="onClose" :disabled="isSaving">{{
				'Cancel'
			}}</v-btn>
			<v-spacer></v-spacer>
		</v-card-actions>
		<v-card-actions class="d-flex d-sm-none">
			<v-menu offset-x>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="btn-blue"
						dark
						v-bind="attrs"
						v-on="on"
						:loading="isSaving"
						class="d-flex align-center"
					>
						{{ 'Actions' }}
						<v-divider vertical class="mx-2" />
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</template>
				<v-list dense class="text-uppercase">
					<v-list-item @click="onSaveForm()">
						<v-list-item-content>
							<v-list-item-title>{{ 'save' }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-spacer></v-spacer>
			<v-btn outlined text class="text-capitalize primary--text btn-white" @click="onClose" :disabled="isSaving">{{
				'Cancel'
			}}</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import AccountingService from '../services/accounting.service';
import accountingGlobalMethods, { apiErrorMessage } from '../utils/accountingGlobalMethods';
// import moment from 'moment';

export default {
    name: "DefaultSettings",
    components: {
		// 
    },
	props: ['open'],
	data() {
		return {
			formData: {
                journal_number_prefix: '',
                journal_number_digit: '',
                journal_number_next: '',
                accounts_receivable: '',
                accounts_payable: '',
                accounts_sales: '',
                accounts_expenses: '',
                accounts_sales_discount: '',
                accounts_purchase_discount: '',
                accounts_owners_contribution: '',
                types_bank: '',
                types_tax: '',
			},
			formValid: true,
			isSaving: false,
            accountsOptions: [],
            typesOptions: [],
            defaultValues: {},
		};
	},

	created() {
        this.getDoubleEntrySettings();
	},

	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.mobile;
		}
	},

	watch: {
        // 
	},

	methods: {
		...accountingGlobalMethods,
		async getDoubleEntrySettings(){
			try {
				const { data } = await AccountingService.getDoubleEntrySettings();
				if(data.data){

                    const options = data.data.options;
                    const accounts_options = options.account_options;
                    const types_options = options.type_options;
                    const defaults = data.data.defaults;

                    let _dataAccounts = [];
                    Object.keys(accounts_options).forEach((key) => {
						_dataAccounts.push({ header: key });
						Object.keys(accounts_options[key]).forEach((k) => {
							_dataAccounts.push({
								text: accounts_options[key][k],
								value: parseInt(k)
							});
						});
					});

                    let _dataTypes = [];
                    Object.keys(types_options).forEach((key) => {
						_dataTypes.push({ header: key });
						Object.keys(types_options[key]).forEach((k) => {
							_dataTypes.push({
								text: types_options[key][k],
								value: parseInt(k)
							});
						});
					});

                    this.accountsOptions = _dataAccounts;
                    this.typesOptions = _dataTypes;
                    this.defaultValues = defaults;

                    this.formData = {
                        ...this.formData,
                        journal_number_prefix: defaults.journal_number_prefix,
                        journal_number_digit: parseInt(defaults.journal_number_digit),
                        journal_number_next: parseInt(defaults.journal_number_next),
                        accounts_receivable: parseInt(defaults.accounts_receivable),
                        accounts_payable: parseInt(defaults.accounts_payable),
                        accounts_sales: parseInt(defaults.accounts_sales),
                        accounts_expenses: parseInt(defaults.accounts_expenses),
                        accounts_sales_discount: parseInt(defaults.accounts_sales_discount),
                        accounts_purchase_discount: parseInt(defaults.accounts_purchase_discount),
                        accounts_owners_contribution: parseInt(defaults.accounts_owners_contribution),
                        types_bank: parseInt(defaults.types_bank),
                        types_tax: parseInt(defaults.types_tax),
                    }
				}
			
			} catch (error) {
                console.log(error);
				apiErrorMessage(error);
			}
		},

		onClose() {
			this.$refs.localizationSettingsForm.resetValidation();
			this.$emit('toggle');
		},

		async onSaveForm() {
			if (this.isSaving) {
				return;
			}
			const validated = this.$refs.localizationSettingsForm.validate();
			if (validated) {
				this.isSaving = true;
				try {
					const { data } = await AccountingService.saveDoubleEntrySettings({
						...this.formData,
					});
					const message = data.message || 'Data was successfully saved.';
                    this.$emit('toggle', { created: true, message });
				} catch (error) {
					const { data } = error.response || { data: {} };
					const message = data.message || 'Could not save the data.';
					this.$emit('toggle', { created: false, message });
				} finally {
					this.showSnackbar = true;
					this.isSaving = false;
				}
			}
		},
	}
};
</script>

<style lang="scss">
@import '../../../src/assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../../src/assets/scss/buttons.scss';
@import '../views/scss/globalAccounting.scss';
</style>

<style lang="scss" scoped>
	$form-label: #819fb2;
	$border-color: #21606bab;
	$btn-active-color: #0171a1;

	.form-label,
	.v-label {
		color: $form-label;
	}
	.w-100 {
		width: 100%;
	}
	.display-none {
		display: none;
	}

	fieldset {
		border: 1px solid $border-color;
		border-radius: 5px 5px 5px 5px;
	}

	fieldset legend {
		border: 1px solid;
		border-color: $border-color;
		background-color: #f5f5f5;
	}
	.btn-primary {
		background-color: $btn-active-color !important;
		color: #ffffff !important;
	}
	::v-deep {
		.v-dialog {
			.v-input__control {
				background: transparent !important;
				margin-bottom: 12px !important;

				.v-input__slot {
					min-height: 45px !important;

					label {
						line-height: 18px;
						font-size: 14px;
						color: #B4CFE0;
						font-family: 'Inter-Regular', sans-serif;
					}

					input,
					textarea {
						font-size: 14px;
						font-family: 'Inter-Regular', sans-serif;
					}

					.v-select__slot {
						.v-select__selections {
							.v-select__selection {
								font-size: 14px;
							}
						}
					}

					.v-input__append-inner {
						button {
							font-size: 18px;
							color: #ff5252!important;
						}
					}
				}

				.v-text-field__details {
					margin-bottom: 0;
					padding-top: 5px !important;
				}
			}

			.v-input.v-input--is-focused {
				fieldset {
					border: 1px solid #0171A1 !important;
				}
			}

			.v-text-field--enclosed .v-input__prepend-inner {
				margin-top: 0 !important;
			}

			.item-form-column {
				padding: 20px 24px !important;

				.form-label {
					font-size: 10px;
					color: #819fb2;
					font-family: "Inter-SemiBold",sans-serif;
					text-transform: uppercase;
					margin-bottom: 0;
				}
			}

			.item-legend-rounded-wrapper {
				border: 1px solid #D8E7F0;

				.item-legend-rounded {
					border: 1px solid #D8E7F0;
					padding: 6px 16px !important;
					text-transform: uppercase;
					color: #6D858F;
					font-size: 12px;
					font-family: 'Inter-Medium', sans-serif;
				}
			}			
		}

		.v-select--is-menu-active {
			background: transparent !important;
		}

		fieldset {
			border: 1px solid #b3cfe0;
			font-size: 14px;
		}

		fieldset:focus {
			border: 1px solid #b3cfe0 !important;
			outline: 0;
		}

		.v-text-field__slot input::placeholder {
			color: #b4cfe0 !important;
		}
	}
</style>
